import * as React from "react"
import { Link } from "gatsby"
import { isSmallScreen } from "../utils/media-queries"

const HeaderLinks = ({ location }) => {
  const url = location?.pathname ?? ""
  return (
    <nav>
      <ul
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `1rem`,
          display: `flex`,
          listStyle: `none`,
          justifyContent: `space-between`,
        }}
        className="header-links col-small-row-large"
      >
        <li>
          <Link
            to="/"
            style={{
              color: `var(--header-text-color)`,
              textDecoration: `none`,
              fontWeight: url === "/" || url === "" ? `bold` : `normal`,
            }}
          >
            Home
          </Link>
        </li>
        <li>
          <Link
            to="/blog"
            style={{
              color: `var(--header-text-color)`,
              textDecoration: `none`,
              fontWeight: url.startsWith("/blog") ? `bold` : `normal`,
            }}
          >
            Developer blog
          </Link>
        </li>
        <li>
          <Link
            to="/writing"
            style={{
              color: `var(--header-text-color)`,
              textDecoration: `none`,
              fontWeight: url.startsWith("/writing") ? `bold` : `normal`,
            }}
          >
            Other writing
          </Link>
        </li>
        <li>
          <Link
            to="/about"
            style={{
              color: `var(--header-text-color)`,
              textDecoration: `none`,
              fontWeight: url.startsWith("/about") ? `bold` : `normal`,
            }}
          >
            About
          </Link>
        </li>
      </ul>
    </nav>
  )
}

export default HeaderLinks
