import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import HeaderLinks from "./header-links"

const Header = ({ siteTitle, location }) => (
  <header
    className="row-small-col-large"
    style={{
      marginBottom: `1.45rem`,
      borderBottom: '1px solid',
      backgroundColor: 'var(--header-background-color)',
      display: 'flex',
    }}
    >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `var(--header-text-color)`,
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
      </h1>
    </div>
    <HeaderLinks location={location}></HeaderLinks>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
